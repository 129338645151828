
import * as contentfulRuntime$5quvYO0ILO from '/codebuild/output/src2964691665/src/horizon/node_modules/@nuxt/image/dist/runtime/providers/contentful'
import * as cloudfrontRuntime$Nd7DzOqXgy from '/codebuild/output/src2964691665/src/horizon/img-providers/cloudfront.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 415,
    "md": 767,
    "lg": 1000,
    "xl": 1080,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "contentful",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "png",
    "jpeg",
    "jpg",
    "svg",
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['contentful']: { provider: contentfulRuntime$5quvYO0ILO, defaults: {"baseURL":"https://images.ctfassets.net/"} },
  ['cloudfront']: { provider: cloudfrontRuntime$Nd7DzOqXgy, defaults: {"baseURL":"https://d9ylfbta65t3x.cloudfront.net"} }
}
        